import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Layout from '../layouts'
import MetaHeader from '../components/MetaHeader'
import PageWrapper from '../components/PageWrapper'
import PageIntro from '../components/PageIntro'

const Wrapper = styled.div`
  margin-bottom: 4rem;
`

const Mission = ({ data }) => {
  const {
    contentfulPageHeader: {
      heading,
      subheading,
      image,
      metaTitle,
      metaDescription,
      metaKeywords,
      metaSiteUrl,
      metaTwitter,
    },
  } = data

  return (
    <Layout>
      <MetaHeader
        title={metaTitle || heading}
        image={image?.file?.url}
        siteUrl={metaSiteUrl}
        description={metaDescription?.metaDescription}
        keywords={metaKeywords?.metaKeywords}
        twitter={metaTwitter}
      />
      <Wrapper>
        <PageWrapper>
          <PageIntro
            title={heading}
            subtitle={subheading}
            imgSrc={image?.file?.url}
            alt={image?.title}
          />
          <p>
            At Female Funders, we value inclusion, diversity, measurable impact,
            and transparency in the finance industry. Our mission is to increase
            diversity in the investment and tech ecosystems by empowering female
            leaders to become investors. Demystifying the path to becoming an
            investor is an important first step.
          </p>

          <h2>Vision</h2>
          <p>
            Women have the expertise and the capital to make an impact. Through
            education, access to investment opportunities, and a global
            community, Female Funders is giving them the tools to become
            early-stage investors — and ultimately shaping the future of
            funding.
          </p>

          <h2>History</h2>
          <p>
            What began as a desire to increase diversity in the financial and
            tech industries led to Female Funders founder Katherine Hague
            setting a goal of supporting one thousand women in making their
            first angel investment. That BHAG (big, hairy, audacious goal)
            evolved into a community for aspiring angel investors to learn,
            connect, and invest. The first Angel Academy class graduated in
            December of 2015.
          </p>

          <p>
            In 2017, Female Funders was acquired by Highline BETA, a venture
            capital and startup co-creation company, with the intention of
            evolving the Angel Academy curriculum and expanding the Female
            Funders community to help even more women make better investments
            and generate solid returns.
          </p>

          <p>
            Although women are mentors, advisors, and champions, we often don’t
            self-identify as investors. Today, women have the opportunity to put
            our capital to work – and shape the future of innovation – as
            investors. Female Funders is proud to work with the National Angel
            Capital Organization (NACO) and many of North America’s top venture
            capital firms to support education and increase access for women.
          </p>
        </PageWrapper>
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query {
    contentfulPageHeader(id: { eq: "c5868b0f-d25c-5479-adc1-d7fb103656e8" }) {
      id
      heading
      image {
        file {
          url
        }
      }
      metaTitle
      metaDescription {
        metaDescription
      }
      metaKeywords {
        metaKeywords
      }
      metaSiteUrl
      metaTwitter
    }
  }
`

export default Mission
